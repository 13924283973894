<template>
  <div>
    <v-breadcrumbs :items="crumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-container>
      <v-card>
        <v-list>
          <v-subheader>フロアを選択して下さい</v-subheader>
          <v-list-item-group>
            <v-divider />
            <v-list-item to="/events/5F">
              KLS-5F【セミナー/小会議室】
            </v-list-item>
            <v-divider />
            <v-list-item to="/events/B1">KLS-B1【Catheter Room】</v-list-item>
            <v-divider />
            <v-list-item to="/events/2F">KLS-2F</v-list-item>
            <v-divider />
            <v-list-item to="/events/NLS">NLS</v-list-item>
            <v-divider />
            <v-list-item to="/events/SLS">SLS</v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-list>
          <v-subheader>
            パスワード設定するフロアを選択してください
          </v-subheader>
          <v-list-item-group>
            <v-divider />
            <v-list-item to="/accessctl/2F"> KLS-2F </v-list-item>
            <v-divider />
            <v-list-item to="/accessctl/NLS">NLS</v-list-item>
            <v-divider />
            <v-list-item to="/accessctl/SLS">SLS</v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    crumbs: [{ text: 'top', disabled: false, to: '/top' }],
  }),
})
</script>
